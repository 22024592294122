import React from "react"

const REDIRECT_URL = "/en/"

const IndexPage = () => {
  // console.debug("Rendering IndexPage", props)
  return (
    <p>
      Content moved to <a href={REDIRECT_URL}>{REDIRECT_URL}</a>
    </p>
  )
}

export default IndexPage

export function Head() {
  return (
    <>
      <meta httpEquiv="refresh" content={`0; url=${REDIRECT_URL}`} />
      <meta name="robots" content="noindex" />
      <link rel="canonical" href={REDIRECT_URL} />
      <title>Harvia</title>
    </>
  )
}
